"use client";

import Image from "next/image";
import { FC, useEffect, useRef, useState } from "react";

const MenuItem: FC<{ name: string; href: string }> = ({ name, href }) => {
  return (
    <li key={name} className="inline-block mr-10">
      <a className="no-underline text-[#333]" href={href}>
        {name}
      </a>
    </li>
  );
};
const Header = () => {
  const [visible, setVisible] = useState<boolean>(true);
  const prevScrollY = useRef<number>(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const start = prevScrollY.current;
      const isScrolledDown = currentScrollY > prevScrollY.current;

      setVisible(
        (isScrolledDown && currentScrollY - start < 400) ||
          (!isScrolledDown && start - currentScrollY > 100) ||
          currentScrollY < 400
      );

      prevScrollY.current = currentScrollY;
    };

    const debouncedHandleScroll = debounce(handleScroll, 100);

    window.addEventListener("scroll", debouncedHandleScroll);

    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, []);

  const debounce = (func: any, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (...args: any[]) {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  //bg-[#0cc0df]

  return (
    <header
      className={`max-[490px]:bg-[#efeeff] sm:bg-white fixed z-10 bg-white top-[0] left-[0] w-full h-[60px] [transition:transform_0.3s_ease] p-3 md:p-[20px] ${
        visible ? "translate-y-[0]" : "-translate-y-full"
      }`}
    >
      <nav className="flex max-[490px]:justify-center min-[490px]:justify-between">
        <Image src="/images/logo.webp" height={27} width={100} alt="logo" />
        <ul className="max-[490px]:hidden">
          <MenuItem name="Home" href="#home" />
          <MenuItem name="Advertise" href="#advertise" />
          <MenuItem name="Contact" href="#contact" />
        </ul>
      </nav>
    </header>
  );
};
export default Header;
